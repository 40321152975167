import styled from "styled-components/macro";
import tailwind from "twin.macro";

export const List = styled.ul`
  ${({ open }: { open: boolean }) =>
    open
      ? tailwind`
    block
  `
      : tailwind`
    hidden
    lg:block
  `}

  ${tailwind`
    text-sm
    font-medium
  `}
`;

export const ListItem = styled.li`
  ${tailwind`
    flex
    items-start
    mb-4
    space-x-4
  `}
`;

export const ListItemContainer = styled.div`
  ${tailwind`
    flex-auto
    space-y-1
  `}
`;

export const Amount = styled.p`
  ${tailwind`
    flex-none
    text-base
    font-medium
    text-gray-900
    lg:text-white
  `}
`;

export const Description = styled.p`
  ${tailwind`
    text-xs
  `}
`;

export const Title = styled.h3`
  ${tailwind`
    text-gray-900
    lg:text-white
  `}
`;
