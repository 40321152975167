import axios from "axios";

export let baseURL = "https://api.usezapay.com.br";
// export let baseURL = "https://api.sandbox.usezapay.com.br";

switch (process.env.REACT_APP_ENV) {
  case "staging":
    baseURL = "https://api-test.staging.usezapay.com.br";
    break;
  case "production":
    baseURL = "https://api.usezapay.com.br";
    break;
  case "sandbox":
    baseURL = "https://api.sandbox.usezapay.com.br";
    break;
  default:
    break;
}

axios.defaults.baseURL = `${baseURL}`;

export default axios;
