import React, { Fragment } from 'react';
import ErrorModal from './Error';

type ExpiredModalProps = {
  visible: boolean;
  toggleVisibility: Function;
};

export default function ConnectionModal(props: ExpiredModalProps) {
  return (
    <ErrorModal
      {...props}
      heading="Falha ao aplicar cupom"
      content={(
        <>
          <p>Não foi possível aplicar o cupom.</p>
          <p>Tente novamente em instantes.</p>
        </>
      )}
    />
  );
}
