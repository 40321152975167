import { Label, InputContainer, Input } from "./styles";

type ComplementProps = {
  register: Function;
};

const INPUT_ADDRESS_COMPLEMENT_NAME = "address.complement";

export default function Complement({ register }: ComplementProps) {
  return (
    <div>
      <Label htmlFor={INPUT_ADDRESS_COMPLEMENT_NAME}>Complemento</Label>
      <InputContainer>
        <Input
          {...register(INPUT_ADDRESS_COMPLEMENT_NAME)}
          type="text"
          autoComplete="off"
        />
      </InputContainer>
    </div>
  );
}
