// @ts-nocheck

import CardValidator from "card-validator";
import { Controller, useWatch } from "react-hook-form";
import {
  Label,
  InputContainer,
  InputIconContainer,
  Input,
  InputIconErrorContainer,
  InputError,
} from "./styles";
import { INPUT_CREDIT_CARD_NUMBER_NAME } from "./constants";
import { useCreditCardFlip } from "../../../contexts/credit-card-flip";
import NumberFormat from "react-number-format";

const INPUT_CREDIT_CARD_CVV_NAME = "card.cvv";

const validateCVV = (cardNumber: string, value: string) => {
  const cardNumberDigits = cardNumber.replace(/[^\d]/g, "");
  const digits = value.replace(/[^\d]/g, "");

  const maxLength = 16;

  const validation = CardValidator.number(cardNumberDigits, {
    maxLength,
  });

  if (validation.isPotentiallyValid) {
    const { type, code } = validation.card || {};
    const { size } = code || {};
    if (type === "american-express" && digits.length !== size) {
      return "Código de segurança inválido.";
    }
  } else if (digits.length !== 3) {
    return "Código de segurança inválido.";
  }
};

const cvvMask = (value: string) => {
  const digits = value.replace(/[^\d]/g, "");

  const maxLength = 16;

  const validation = CardValidator.number(digits, {
    maxLength,
  });

  if (validation.isPotentiallyValid) {
    const { type } = validation.card || {};
    if (type === "american-express") {
      return "####";
    }
  }

  return "###";
};

type CVVProps = {
  control: Function;
  errors: any;
};

export default function CVV({ control, errors }: CVVProps) {
  const cardNumber = useWatch({
    control,
    name: INPUT_CREDIT_CARD_NUMBER_NAME,
    defaultValue: "",
  });

  const { setCreditCardFlip } = useCreditCardFlip();

  return (
    <>
      <Label error={errors} htmlFor={INPUT_CREDIT_CARD_CVV_NAME}>
        Código de Segurança
      </Label>
      <InputContainer>
        <InputIconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
          </svg>
        </InputIconContainer>
        <Controller
          name={INPUT_CREDIT_CARD_CVV_NAME}
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: "Insira o número do cartão.",
            },
            validate: (value) => validateCVV(cardNumber, value),
          }}
          render={({ field: { value, onChange, onBlur, ref } }) => {
            const mask = cvvMask(cardNumber);
            const placeholder = mask.replaceAll("#", "0");
            return (
              <NumberFormat
                format={mask}
                value={value}
                onChange={onChange}
                onFocus={() => setCreditCardFlip(true)}
                onBlur={(event) => {
                  onBlur(event);
                  setCreditCardFlip(false);
                }}
                customInput={Input}
                autoComplete="cc-csc"
                placeholder={placeholder}
                error={errors}
                getInputRef={ref}
              />
            );
          }}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </>
  );
}
