import { Fragment } from "react";
import tailwind from "twin.macro";
import styled from "styled-components";
import { Theme } from "themes/types";
import Modal from "components/Modal/Modal";

const Icon = styled.div`
  ${tailwind`
    mx-auto
    flex
    items-center
    justify-center
    h-12
    w-12
    rounded-full
  `}

  svg {
    ${tailwind`
      animate-spin
      h-16
      w-16
    `}

    ${({ theme }: { theme: Theme }) =>
      `
    color: ${theme.colors.primary};
  `}

    circle {
      ${tailwind`
        opacity-25
      `}
    }

    path {
      ${tailwind`
        opacity-75
      `}
    }
  }
`;

const Content = styled.div`
  ${tailwind`
    mt-4
  `}

  p {
    ${tailwind`
      text-sm
      text-gray-500
      mb-3
    `}
  }
`;

const Heading = styled.h3`
  ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-900
  `}
`;

const Container = styled.div`
  ${tailwind`
    mt-3
    text-center
    sm:mt-5
  `}
`;

type PaymentProcessingModalProps = {
  visible: boolean;
  control?: any;
};

export default function PaymentProcessingModal({
  visible,
  control,
}: PaymentProcessingModalProps) {
  if (!visible) {
    return <Fragment />;
  }

  return (
    <Modal>
      <Icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </Icon>
      <Container>
        <Heading>Processando seu Pagamento</Heading>
        <Content>
          <p>Aguarde enquanto processamos seu pagamento. </p>
        </Content>
      </Container>
    </Modal>
  );
}
