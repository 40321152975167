import { useTheme } from "styled-components";
import { usePaymentMethod } from "contexts/payment-method";
import { useOrder } from "contexts/order";

export const useRedirectToECommerce = () => {
  const { orderOnSameHost } = useTheme();
  const { paymentMethod } = usePaymentMethod();
  const { protocol, store } = useOrder();

  const isPix = paymentMethod === "pix";

  const isB2B = store?.id !== 662; // E-commerce B2C
  let host = isB2B
    ? process.env.REACT_APP_B2B_CHECKOUT_URL
    : process.env.REACT_APP_SITE_URL;

  if (orderOnSameHost) {
    host = window.location.origin;
  }

  const trackingUrl = `${host}/${isPix ? "pix" : "pedidos"}/${protocol}`;

  return { trackingUrl, isPix };
};
