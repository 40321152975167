import styled from "styled-components/macro";
import tailwind from "twin.macro";

export const Container = styled.div`
  ${tailwind`
    mt-4
    text-center
    text-xs
    text-gray-800
    sm:mt-0
    sm:text-left mb-6
  `}
`;

export const Link = styled.a`
  ${tailwind`
    ml-1
    text-gray-700
    hover:text-blue-900
    underline
  `}
`;
