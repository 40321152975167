import { useOrder } from "contexts/order";
import {
  Amount,
  Description,
  List,
  ListItem,
  ListItemContainer,
  Title,
} from "./styles";

type CartProps = {
  open: Boolean;
};

export default function Cart({ open }: CartProps) {
  const { debts } = useOrder();
  return (
    <List open={open} role="list">
      {debts.map(({ id, title, description, amount }) => (
        <ListItem key={id + title}>
          <ListItemContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </ListItemContainer>
          <Amount>
            {amount.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </Amount>
        </ListItem>
      ))}
    </List>
  );
}
