import BaseAmount from "components/Amount/Base";
import DiscountAmount from "components/Amount/Discount";
import TotalAmount from "components/Amount/Total";
import Cart from "components/Cart/Cart";
import { useCoupon } from "contexts/coupon";
import { useState } from "react";
import { useTheme } from "styled-components";
import {
  Container,
  CouponBadge,
  Heading,
  HeadingButton,
  HeadingContainer,
  Section,
  SubtotalAmount,
  TotalAmountContainer,
} from "./styles";
import ServiceCharge from "components/Amount/service-charge";

type SummaryProps = {
  mobile: boolean;
};

export default function Summary({ mobile }: SummaryProps) {
  const { title } = useTheme();
  const [cart, setCart] = useState(mobile ? true : false);
  const { validated: couponValidated, code: couponCode } = useCoupon();

  return (
    <Section mobile={mobile}>
      <Container>
        <HeadingContainer>
          <Heading>Resumo do Pedido</Heading>
          <HeadingButton onClick={() => setCart(!cart)} type="button">
            {!cart && (
              <>
                <span>Mostrar Débitos</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </>
            )}
            {cart && (
              <>
                <span>Esconder Débitos</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              </>
            )}
          </HeadingButton>
        </HeadingContainer>
        <Cart open={cart} />
        <SubtotalAmount>
          <div>
            <dt>Total em débitos</dt>
            <dd>
              <BaseAmount />
            </dd>
          </div>
          {title === 'bb' ? (
            <div className="service">
              <dt>Taxas de serviço</dt>
              <dd>
                <ServiceCharge />
              </dd>
            </div>
          ) : null}
          {couponValidated && (
            <div>
              <dt>
                Descontos
                <CouponBadge>{couponCode}</CouponBadge>
              </dt>
              <dd>
                <DiscountAmount />
              </dd>
            </div>
          )}
        </SubtotalAmount>
        <TotalAmountContainer>
          <dt>Total a pagar</dt>
          <dd>
            <TotalAmount />
          </dd>
        </TotalAmountContainer>
      </Container>
    </Section>
  );
}
