import React, { Fragment } from 'react';
import ErrorModal from './Error';

type InvalidModalProps = {
  visible: boolean;
  toggleVisibility: Function;
};

export default function InvalidModal(props: InvalidModalProps) {
  return (
    <ErrorModal
      {...props}
      heading="Cupom Inválido"
      content={(
        <>
          <p>O cupom inserido não foi encontrado.</p>
          <p>Verifique o código e tente novamente.</p>
        </>
      )}
    />
  );
}
