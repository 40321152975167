import React from 'react';
import { useOrder } from 'contexts/order';


export default function BaseAmount() {
  const { debts } = useOrder();
  const baseAmount = debts.map(({ amount }) => amount).reduce((a, b) => a + b, 0);
  return (
    <span>
      {baseAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
    </span>
  );
}
