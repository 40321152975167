import Modal from "components/Modal/Modal";
import React, { Fragment, ReactNode } from "react";
import styled from "styled-components/macro";
import tailwind from "twin.macro";

const Icon = styled.div`
  ${tailwind`
    mx-auto
    flex
    items-center
    justify-center
    h-12
    w-12
    rounded-full
    bg-red-100
  `}

  svg {
    ${tailwind`
      animate-pulse
      h-6
      w-6
      text-red-600
    `}
  }
`;

const Heading = styled.h3`
  ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-900
  `}
`;

const Content = styled.div`
  ${tailwind`
    mt-4
  `}

  p {
    ${tailwind`
      text-sm
      text-gray-500
    `}
  }
`;

const Container = styled.div`
  ${tailwind`
    mt-3
    text-center
    sm:mt-5
  `}
`;

const Button = styled.div`
  cursor: pointer;

  ${tailwind`
    mt-5
    sm:mt-6
    inline-flex
    justify-center
    w-full
    rounded-md
    border
    border-transparent
    shadow-sm
    px-4
    py-2
    bg-red-600
    text-base
    font-medium
    text-white
    hover:bg-red-700
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-red-500
    sm:text-sm
  `}
`;

type ErrorModalProps = {
  visible: boolean;
  toggleVisibility: Function;
  heading: string;
  content: ReactNode;
};

export default function ErrorModal({
  visible,
  toggleVisibility,
  heading,
  content,
}: ErrorModalProps) {
  if (!visible) {
    return <Fragment />;
  }

  return (
    <Modal>
      <div>
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </Icon>
        <Container>
          <Heading>{heading}</Heading>
          <Content>{content}</Content>
        </Container>
      </div>
      <Button onClick={() => toggleVisibility(false)} type="button">
        Ok
      </Button>
    </Modal>
  );
}
