// import React, { Fragment } from "react";
// import { useAddress } from "../../../contexts/address";
import Number from "./Number";
import Complement from "./Complement";
import styled from "styled-components/macro";
import tailwind from "twin.macro";

const Container = styled.div`
  ${tailwind`
    mt-4
    grid
    grid-cols-1
    gap-y-6
    gap-x-4
    sm:grid-cols-2
  `}
`;

type CompleteAddressProps = {
  register: Function;
  errors: any;
};

export default function CompleteAddress({
  register,
  errors,
}: CompleteAddressProps) {
  // const { fetched } = useAddress();

  // if (!fetched) {
  //   return <Fragment />;
  // }

  return (
    <Container>
      <Number register={register} errors={errors?.number} />
      <Complement register={register} />
    </Container>
  );
}
