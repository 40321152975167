import styled from "styled-components/macro";
import { Theme } from "themes/types";
import tailwind from "twin.macro";

export const Container = styled.div`
  ${tailwind`
    fixed
    z-10
    inset-0
    overflow-y-auto
  `}
`;

export const ModalContainer = styled.div`
  ${tailwind`
    flex
    items-end
    justify-center
    min-h-screen
    pt-4
    px-4
    pb-20
    text-center
    sm:block sm:p-0
  `}
`;

export const Overlay = styled.div`
  ${tailwind`
    fixed
    inset-0
    transition-opacity
  `}
  ${({ theme }: { theme: Theme }) =>
    `
    background: ${theme.colors.primary};
  `}
`;

export const ModalDialog = styled.div`
  ${tailwind`
    inline-block
    align-bottom
    rounded-lg
    px-4
    pt-5
    pb-4
    text-left
    overflow-hidden
    transform
    transition-all
    sm:my-8
    sm:align-middle
    sm:max-w-sm sm:w-full sm:p-6
    z-50
  `}
`;

export const Centerize = styled.span`
  ${tailwind`
    hidden
    sm:inline-block
    sm:align-middle
    sm:h-screen
  `}
`;

export const Icon = styled.div`
  ${tailwind`
    mx-auto
    flex
    items-center
    justify-center
    h-12
    w-12
    rounded-full
  `}

  svg {
    ${tailwind`
      animate-spin
      h-16
      w-16
      text-white
    `}

    circle {
      ${tailwind`
        opacity-25
      `}
    }

    path {
      ${tailwind`
        opacity-75
      `}
    }
  }
`;

export const Content = styled.div`
  ${tailwind`
    mt-4
  `}

  p {
    ${tailwind`
      text-sm
      text-white
      mb-3
    `}
  }
`;

export const Heading = styled.h3`
  ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-100
  `}
`;

export const ModalContent = styled.div`
  ${tailwind`
    mt-3
    text-center
    sm:mt-5
  `}
`;
