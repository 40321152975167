import { useQuery } from "hooks/query";
import axios from "axios";
import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useOrder } from "./order";

type DataState = {
  plans: any[];
  selected: number;
  tax_value: number;
};

type States = {
  error: boolean;
  loading: boolean;
};

type InstallmentsContextType = {
  states: States;
  dataPlans: DataState;
  setSelected: (value: number) => void;
};

const DEF_INSTALLMENTS = {
  plans: [],
  tax_value: 0,
  selected: 1,
};

const DEF_STATES = {
  error: false,
  loading: true,
};

const InstallmentsContext = createContext<InstallmentsContextType>({
  states: DEF_STATES,
  dataPlans: DEF_INSTALLMENTS,
  setSelected: (value: number) => {},
});

export const useInstallments = () => useContext(InstallmentsContext);

type Props = {
  children: ReactNode;
};

export function InstallmentsContextProvider({ children }: Props) {
  const query = useQuery();
  const [states, setStates] = useState(DEF_STATES);
  const { payment_plans, protocol, debts } = useOrder();
  const [dataPlans, setDataPlans] = useState(DEF_INSTALLMENTS);

  const getSelected = useCallback(
    (plans: any[]) => {
      const selected = query.get("parcelas");

      const options = plans
        .filter(({ type }) => type === "credit")
        .map(({ installments }) => installments);

      if (selected && options.includes(parseInt(selected))) {
        return parseInt(selected);
      }

      return DEF_INSTALLMENTS.selected;
    },
    [query]
  );

  const onRequestPlans = useCallback(() => {
    setStates({ error: false, loading: true });

    const cart = (debts || []).map((d) => d?.id || "");

    axios
      .post(`/v2/detran/installments-plans/`, {
        cart,
        protocol,
      })
      .then(({ data }) => {
        const plans = data?.installmentsPlans || [];

        setDataPlans({
          plans,
          tax_value: data?.tax_value,
          selected: getSelected(plans),
        });

        setStates({ error: false, loading: false });
      })
      .catch((error) => {
        setStates({ error: true, loading: false });
      });
  }, [debts, protocol, getSelected]);

  const setSelected = (selected: number) => {
    setDataPlans((stt) => ({ ...stt, selected }));
  };

  useEffect(() => {
    if (protocol) {
      const flow = query.get("plan-flow");

      if (flow === "req-plans" && protocol) {
        onRequestPlans();
        return;
      }

      setDataPlans({
        tax_value: 0,
        plans: payment_plans,
        selected: getSelected(payment_plans),
      });
      setStates({ error: false, loading: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocol]);

  return (
    <InstallmentsContext.Provider value={{ states, dataPlans, setSelected }}>
      {children}
    </InstallmentsContext.Provider>
  );
}
