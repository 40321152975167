import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import axios from "api";
import styled from "styled-components/macro";
import tailwind from "twin.macro";
import { useWatch } from "react-hook-form";
import { usePaymentMethod } from "contexts/payment-method";
import { useCoupon } from "contexts/coupon";
import { useOrder } from "../../contexts/order";
import SuccessModal from "./Modal/Success";
import ConnectionModal from "./Modal/Connection";
import ExpiredModal from "./Modal/Expired";
import InvalidModal from "./Modal/Invalid";
import { useQuery } from "hooks/query";
import { useInstallments } from "contexts/installments";

const Heading = styled.h3`
  ${tailwind`
    text-lg
    font-medium
    text-gray-900
  `}
`;

const Label = styled.label`
  ${tailwind`
    block
    text-sm
    font-medium
    text-gray-700
  `}
`;

const Input = styled.input`
  ${tailwind`
    focus:ring-indigo-500
    h-10
    block
    w-full
    rounded-none
    rounded-l-md
    pl-10
    sm:text-sm
  `}
`;

const ApplyButton = styled.button`
  ${tailwind`
    -ml-px
    relative
    inline-flex
    items-center
    space-x-2
    px-4
    py-2
    border
    border-gray-300
    text-sm
    font-medium
    rounded-r-md
    text-gray-700
    bg-gray-50
    hover:bg-gray-100
    focus:outline-none
    focus:ring-1
    focus:ring-indigo-500
    focus:border-indigo-500
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-gray-400
    `}
  }
`;

const Grid = styled.div`
  ${tailwind`
    mt-6
    grid
    grid-cols-2
  `}
`;

const Container = styled.div`
  ${tailwind`
    mt-10
  `}
`;

const InputWrapper = styled.div`
  ${tailwind`
    col-span-2
    sm:col-span-1
  `}
`;

const InputContainer = styled.div`
  ${tailwind`
    mt-1
    flex
    rounded-md
    shadow-sm
  `}
`;

const InputInnerContainer = styled.div`
  ${tailwind`
    relative
    flex
    items-stretch
    flex-grow
    focus-within:z-10
    border
    border-gray-300
    focus:border-indigo-500
    rounded-l-md
  `}
`;

const InputIconContainer = styled.div`
  ${tailwind`
    absolute
    inset-y-0
    left-0
    pl-3
    flex
    items-center
    pointer-events-none
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-gray-400
    `}
  }
`;

const RemoveButton = styled.button`
  ${tailwind`
    mt-6
    appearance-none
    font-semibold
    text-gray-700
    hover:text-blue-900
    text-sm
    underline
  `}
`;

const INPUT_NAME = "coupon";

type CouponProps = {
  control: any;
  register: Function;
};

export default function Coupon({ control, register }: CouponProps) {
  const query = useQuery();
  const defaultValue = query.get("cupom") || '';

  const [validating, setValidating] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [invalidModal, setInvalidModal] = useState(false);
  const [expiredModal, setExpiredModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const { paymentMethod } = usePaymentMethod();
  const { protocol } = useOrder();
  const { dataPlans } = useInstallments();

  const { setCoupon, validated } = useCoupon();

  const couponCode = useWatch({
    control,
    name: INPUT_NAME,
    defaultValue,
  });

  function handleClick(code: string) {
    const code_ = code.toUpperCase();
    setValidating(true);
    const endpoint = `/promotional_tickets/${code_}/get/?protocol=${protocol}`;
    axios
      .get(endpoint)
      .then(({ data }) => {
        const { discount, is_percent } = data;
        setCoupon({
          validated: true,
          code: code_,
          is_percent,
          discount,
        });

        setSuccessModal(true);
      })
      .catch((error) => {
        setErrorModal(true);
      })
      .finally(() => {
        setValidating(false);
      });
  }

  const { coupon: enableCoupon } = useTheme();
  const canUseCoupon = dataPlans.plans.some(({ coupon }) => coupon);
  const disabledComponent = !enableCoupon || paymentMethod !== "credit" || !canUseCoupon;

  useEffect(() => {
    if (!disabledComponent && defaultValue !== '') {
      handleClick(couponCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (disabledComponent) {
    return <></>;
  }

  return (
    <>
      <SuccessModal visible={successModal} toggleVisibility={setSuccessModal} />
      <InvalidModal visible={invalidModal} toggleVisibility={setInvalidModal} />
      <ExpiredModal visible={expiredModal} toggleVisibility={setExpiredModal} />
      <ConnectionModal visible={errorModal} toggleVisibility={setErrorModal} />

      <Container>
        <Heading>Cupom de Desconto</Heading>
        {validated && (
          <RemoveButton onClick={() => setCoupon({ validated: false })}>
            Remover cupom de desconto {couponCode}
          </RemoveButton>
        )}
        {!validated && (
          <Grid>
            <InputWrapper>
              <Label htmlFor={INPUT_NAME}>Código</Label>
              <InputContainer>
                <InputInnerContainer>
                  <InputIconContainer>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                      />
                    </svg>
                  </InputIconContainer>
                  <Input
                    type="text"
                    autoComplete="off"
                    {...register(INPUT_NAME)}
                    defaultValue={defaultValue}
                  />
                </InputInnerContainer>
                <ApplyButton
                  onClick={() => handleClick(couponCode)}
                  type="button"
                  disabled={!couponCode || couponCode.length === 0}
                >
                  {validating && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {!validating && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                  <span>Aplicar</span>
                </ApplyButton>
              </InputContainer>
            </InputWrapper>
          </Grid>
        )}
      </Container>
    </>
  );
}
