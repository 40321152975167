// import { Fragment } from "react";
import styled from "styled-components/macro";
import tailwind from "twin.macro";
import Address from "./Address";
import State from "./State";
import Neighborhood from "./Neighborhood";
import City from "./City";
// import { useAddress } from "contexts/address";

const Container = styled.div`
  ${tailwind`
    grid
    grid-cols-1
    sm:grid-cols-2
    gap-x-4
    gap-y-4
    mt-4
  `}
`;

type ChangeAddressProps = {
  register: Function;
  errors: any;
};

export default function ChangeAddress({
  register,
  errors,
}: ChangeAddressProps) {
  // const { change } = useAddress();

  // // if (!change) {
  // //   return <Fragment />;
  // // }

  return (
    <Container>
      <Address register={register} errors={errors?.address} />
      <Neighborhood register={register} errors={errors?.neighborhood} />
      <City register={register} errors={errors?.city} />
      <State register={register} errors={errors?.state} />
    </Container>
  );
}
