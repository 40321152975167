export const useCheckAllowedTimeRange = () => {
  let isWithinAllowedTimeRange = true;

  const currentDateTime = new Date();

  const timeZone = "America/Sao_Paulo";
  const dateFormat = new Intl.DateTimeFormat("pt-BR", {
    timeZone,
    timeStyle: "long",
  });

  const [{ value: hour }, , { value: minute }] =
    dateFormat.formatToParts(currentDateTime);

  const hours = Number(hour);
  const minutes = Number(minute);

  if (hours > 22 || (hours === 22 && minutes > 0)) {
    isWithinAllowedTimeRange = false;
  }

  return { isWithinAllowedTimeRange };
};
