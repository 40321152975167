import React from "react";
import tailwind from "twin.macro";
import styled from "styled-components/macro";
import { usePaymentMethod } from "contexts/payment-method";
import Installments from "components/Payment/Installments";
import CardNumber from "./CardNumber";
import CVV from "./CVV";
import ExpiryDate from "./ExpirationDate";
import Holder from "./Holder";
import Document from "./Document";
import { CardInfos } from "./card-infos";

type CreditCardFormProps = {
  control: Function;
  register: Function;
  errors: any;
};

const Container = styled.div`
  ${tailwind`
    mt-6
    grid
    grid-cols-2
    gap-y-6
    gap-x-4
    items-start
  `}
`;

const InputContainer = styled.div`
  ${tailwind`
    mt-3
  `}
`;

const InputGrid = styled.div`
  ${tailwind`
    grid
    grid-cols-2
    gap-x-4
  `}
`;

const CardContainer = styled.div`
  ${tailwind`
    md:block
    row-start-1
    col-span-2
    md:row-auto
    md:col-span-1
  `}
`;

const CardInputsContainer = styled.div`
  ${tailwind`
    col-span-2
    md:col-span-1
  `}
`;

export default function CreditCardForm({
  control,
  register,
  errors,
}: CreditCardFormProps) {
  const { paymentMethod } = usePaymentMethod();
  return (
    <Container>
      <CardInputsContainer>
        {paymentMethod === "credit" && (
          <InputContainer>
            <Installments />
          </InputContainer>
        )}
        <InputContainer>
          <CardNumber control={control} errors={errors?.number} />
        </InputContainer>
        <InputGrid>
          <InputContainer>
            <ExpiryDate control={control} errors={errors?.expiration_date} />
          </InputContainer>
          <InputContainer>
            <CVV control={control} errors={errors?.cvv} />
          </InputContainer>
        </InputGrid>
      </CardInputsContainer>
      <CardContainer>
        <CardInfos control={control} />
      </CardContainer>
      <Holder register={register} errors={errors?.holder} />
      <Document control={control} errors={errors?.document} />
    </Container>
  );
}
