import { useQuery } from "hooks/query";
import React from "react";
import {
  Label,
  GridItem,
  Input,
  InputError,
  InputContainer,
  LeadingContainer,
  InputIconErrorContainer,
} from "./styles";

const INPUT_CUSTOMER_EMAIL_NAME = "customer.email";

type EmailProps = {
  register: Function;
  errors: any;
  email?: string;
};

export default function Email({ register, errors, email }: EmailProps) {
  const query = useQuery();
  const defaultValue = query.get("email") || email;  

  return (
    <GridItem>
      <Label error={errors} htmlFor={INPUT_CUSTOMER_EMAIL_NAME}>
        Email
      </Label>
      <InputContainer>
        <LeadingContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
        </LeadingContainer>
        <Input
          type="email"
          autoComplete="email"
          defaultValue={defaultValue ?? ""}
          {...register(INPUT_CUSTOMER_EMAIL_NAME, {
            required: {
              value: true,
              message: "Insira o e-mail.",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "E-mail inválido.",
            },
          })}
          error={errors}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </GridItem>
  );
}
