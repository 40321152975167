import { useOrder } from "contexts/order";
import { Fragment } from "react";
import {
  Centerize,
  Container,
  Content,
  Heading,
  Icon,
  ModalContainer,
  ModalContent,
  ModalDialog,
  Overlay,
} from "./styles";

export default function NotFound() {
  const { notFound } = useOrder();

  if (!notFound) {
    return <Fragment />;
  }

  return (
    <Container role="dialog" aria-modal="true">
      <ModalContainer>
        <Overlay />
        <Centerize>&#8203;</Centerize>
        <ModalDialog>
          <Icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Icon>
          <ModalContent>
            <Heading>Pedido não localizado</Heading>
            <Content>
              <p>Não foi possível localizar o seu pedido. </p>
            </Content>
          </ModalContent>
        </ModalDialog>
      </ModalContainer>
    </Container>
  );
}
