// @ts-nocheck

import { ReactNode, createContext, useState, useContext } from "react";

type AddressContextType = {
  fetched: boolean;
  change: boolean;
  success: boolean;
  zipcode: string;
  state: string;
  address: string;
  city?: string;
  neighborhood?: string;
  setAddress: (value: any) => void;
};

const DEFAULT = {
  fetched: false,
  change: false,
  success: false,
};

const AddressContext = createContext<AddressContextType>({
  ...DEFAULT,
  setAddress: (value) => {},
});

export const useAddress = () => useContext(AddressContext);

type Props = {
  children: ReactNode;
};

export function AddressContextProvider({ children }: Props) {
  const [address, setAddress] = useState(DEFAULT);
  return (
    <AddressContext.Provider value={{ ...address, setAddress }}>
      {children}
    </AddressContext.Provider>
  );
}
