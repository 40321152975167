import styled from 'styled-components/macro';
import tailwind from 'twin.macro';

export const Label = styled.label`
  ${({ error }: { error?: any }) => error ? tailwind`
    text-red-600
  ` : tailwind`
    text-gray-700
  `}

  ${tailwind`
    block
    text-sm
    font-medium
  `}
`;

export const InputContainer = styled.div`
  ${tailwind`
    mt-1
    relative
    rounded-md
    shadow-sm
  `}
`;

export const Input = styled.input`
  ${({ error }: { error: any }) => error ? tailwind`
    border-red-300
    text-red-700
  ` : tailwind`
    border-gray-300
  `}

  ${tailwind`
    px-4
    border
    h-10
    block
    w-full
    rounded-md
    shadow-sm
    focus:ring-indigo-500
    focus:border-indigo-500
    sm:text-sm
  `}
`;

export const InputIconErrorContainer = styled.div`
  ${tailwind`
    absolute
    inset-y-0
    right-0
    pr-3
    flex
    items-center
    pointer-events-none
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-red-500
    `}
  }
`;

export const InputError = styled.p`
  ${tailwind`
    mt-2
    text-sm
    text-red-600
  `}
`;