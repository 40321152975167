import React from "react";
import tailwind from "twin.macro";
import styled from "styled-components/macro";
import { useOrder } from "contexts/order";
import { usePaymentMethod } from "contexts/payment-method";
import { useInstallments } from "contexts/installments";
import { calculateTotalAmount } from "utils/amount";
import { calculateTotalAmountWithDiscount } from "utils/coupon";
import { useCoupon } from "contexts/coupon";

const Container = styled.span`
  ${tailwind`
    text-right
  `}

  small {
    span,
    del {
      ${tailwind`
        float-right
      `}
    }
  }
`;

export default function TotalAmount() {
  const { debts } = useOrder();
  const { dataPlans } = useInstallments();
  const { paymentMethod } = usePaymentMethod();
  const { is_percent, discount, validated } = useCoupon();
  const { total_amount, amount } = calculateTotalAmount(
    dataPlans.plans,
    dataPlans.selected,
    paymentMethod
  );  

  const baseAmount = debts
    .map(({ amount }) => amount)
    .reduce((a, b) => a + b, 0);

  const totalAmount = calculateTotalAmountWithDiscount({
    isPercent: is_percent,
    discount,
    totalAmount: total_amount,
    baseAmount,
  });

  if (validated) {
    return (
      <Container>
        {totalAmount.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
        <br />
        <small>
          <del>
            {total_amount.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </del>
        </small>
      </Container>
    );
  }

  if (paymentMethod === 'pix') {
    return (
      <Container>
        <span>
          {total_amount.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      </Container>
    );
  }

  return (
    <Container>
      <span>
        {dataPlans.selected}x de{" "}
        {amount?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
      </span>
      <br />
      <small>
        <span>
          {total_amount.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      </small>
    </Container>
  );
}
