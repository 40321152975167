export const Alert = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      fill="none"
      viewBox="0 0 51 51"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M25.5 19.25v8.333m0 8.334h.02m-2.905-27.31L5.479 38.206c-.95 1.642-1.425 2.462-1.355 3.136.061.588.37 1.122.847 1.469.548.398 1.496.398 3.393.398h34.27c1.897 0 2.846 0 3.394-.398.477-.347.785-.881.847-1.469.07-.674-.405-1.495-1.356-3.136L28.384 8.608c-.947-1.636-1.42-2.454-2.038-2.729a2.083 2.083 0 00-1.693 0c-.618.275-1.091 1.093-2.038 2.729z"
      ></path>
    </svg>
  );
};
