import styled from 'styled-components/macro';
import tailwind from 'twin.macro';


export const Label = styled.label`
  ${({ error }: { error?: any }) => error ? tailwind`
    text-red-600
  ` : tailwind`
    text-gray-700
  `}

  ${tailwind`
    block
    text-sm
    font-semibold
  `}
`;

export const Heading = styled.h3`
  ${tailwind`
    text-lg
    font-medium
    text-gray-900
  `}
`;

export const Input = styled.input`
  ${({ error }: { error: any }) => error ? tailwind`
    border-red-300
    focus:ring-red-500
    focus:border-red-500
  ` : tailwind`
    border-gray-300
    focus:ring-gray-500
    focus:border-gray-500
  `}

  ${tailwind`
    block
    py-1
    px-3
    w-full
    border
    pl-10
    rounded-md
    shadow-sm
    sm:text-sm h-10
  `}
`;

export const InputContainer = styled.div`
  ${tailwind`
    mt-1
    relative
    rounded-md
    shadow-sm
  `}
`;

export const LeadingContainer = styled.div`
  ${tailwind`
    absolute
    inset-y-0
    left-0
    pl-3
    flex
    items-center
    pointer-events-none
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-gray-400
    `}
  }
`;

export const Grid = styled.div`
  ${tailwind`
    grid
    grid-cols-1
    sm:grid-cols-2
    gap-x-4
  `}
`;

export const GridItem = styled.div`
  ${tailwind`
    mt-6
  `}
`;

export const InputError = styled.p`
  ${tailwind`
    mt-2
    text-sm
    text-red-600
  `}
`;

export const InputIconErrorContainer = styled.div`
  ${tailwind`
    absolute
    inset-y-0
    right-0
    pr-3
    flex
    items-center
    pointer-events-none
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-red-500
    `}
  }
`;
