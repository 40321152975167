import tailwind from "twin.macro";
import { useTheme } from "styled-components";
import styled from "styled-components/macro";
import { useInstallments } from "../../../contexts/installments";
import { useCoupon } from "contexts/coupon";
import { calculateTotalAmountWithDiscount } from "utils/coupon";
import { Theme } from "themes/types";

const ListItem = styled.li`
  ${tailwind`
    text-gray-900
    cursor-default
    select-none
    relative
    py-2
    pl-8
    pr-4
    hover:bg-gray-100
  `}

  cursor: pointer;
  border-radius: 12px;
  border: 1.5px solid #bccfe5;
`;

const Amount = styled.span`
  ${tailwind`
    font-semibold
  `}
  ${({ theme }: { theme: Theme }) =>
    `
    color: ${theme.title === "bb" ? "#E12C6D" : theme.colors.primary};
  `}
`;

const TotalAmount = styled.span`
  ${tailwind`
    text-gray-600
    font-semibold
  `}
`;

const Icon = styled.span`
  ${tailwind`
    text-green-600
    absolute
    inset-y-0
    left-0
    flex
    items-center
    pl-2
  `}

  svg {
    ${tailwind`
      h-4
      w-4
    `}
  }
`;

const Amounts = styled.div`
  ${tailwind`
    flex
    justify-between
  `}

  span {
    font-weight: 700;
  }
`;

type InstallmentOptionProps = {
  installments: number;
  fee: string;
  amount: number;
  totalAmount: number;
  baseAmount: number;
};

export default function InstallmentOptionFees({
  installments,
  fee,
  amount,
  totalAmount,
  baseAmount,
}: InstallmentOptionProps) {
  const { dataPlans, setSelected } = useInstallments();

  const { is_percent, discount, validated } = useCoupon();
  const isOptionSelected = dataPlans.selected === installments;
  const totalAmountWithDiscount = calculateTotalAmountWithDiscount({
    isPercent: is_percent,
    discount,
    totalAmount,
    baseAmount,
  });

  const updatedAmount = validated
    ? totalAmountWithDiscount / installments
    : amount;

  const updatedTotalAmount = validated ? totalAmountWithDiscount : totalAmount;
  const { displayFees } = useTheme();

  return (
    <ListItem role="option" onClick={() => setSelected(installments)}>
      <Amounts>
        <div>
          <span>{installments}x de </span>
          <Amount>
            {updatedAmount.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </Amount>
        </div>
        <TotalAmount>
          {updatedTotalAmount.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </TotalAmount>
      </Amounts>
      {displayFees && (
        <small className="text-gray-400">
          taxa de {String(fee).replace(".", ",")}%
        </small>
      )}
      <Icon>
        {isOptionSelected ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="3" fill="#E12C6D" />
            <rect
              x="1"
              y="1"
              width="14"
              height="14"
              rx="7"
              stroke="#E12C6D"
              strokeWidth="2"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="14" height="14" rx="7" fill="white" />
            <rect
              x="1"
              y="1"
              width="14"
              height="14"
              rx="7"
              stroke="#BCCFE5"
              strokeWidth="2"
            />
          </svg>
        )}
      </Icon>
    </ListItem>
  );
}
