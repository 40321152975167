import React from 'react';
import { useAddress } from 'contexts/address';
import { Label, InputContainer, Select, InputError } from './styles';

const INPUT_STATE_NAME = 'address.state';

const BRAZILIAN_STATES = [{
  name: 'Acre',
  id: 'AC'
}, {
  name: 'Alagoas',
  id: 'AL'
}, {
  name: 'Amapá',
  id: 'AP'
}, {
  name: 'Amazonas',
  id: 'AM'
}, {
  name: 'Bahia',
  id: 'BA'
}, {
  name: 'Ceará',
  id: 'CE'
}, {
  name: 'Distrito Federal',
  id: 'DF'
}, {
  name: 'Espírito Santo',
  id: 'ES'
}, {
  name: 'Goiás',
  id: 'GO'
}, {
  name: 'Maranhão',
  id: 'MA'
}, {
  name: 'Mato Grosso',
  id: 'MT'
}, {
  name: 'Mato Grosso do Sul',
  id: 'MS'
}, {
  name: 'Minas Gerais',
  id: 'MG',
}, {
  name: 'Pará',
  id: 'PA'
}, {
  name: 'Paraíba',
  id: 'PB'
}, {
  name: 'Paraná',
  id: 'PR'
}, {
  name: 'Pernambuco',
  id: 'PE'
}, {
  name: 'Piauí',
  id: 'PI'
}, {
  name: 'Rio de Janeiro',
  id: 'RJ'
}, {
  name: 'Rio Grande do Norte',
  id: 'RN'
}, {
  name: 'Rio Grande do Sul',
  id: 'RS'
}, {
  name: 'Rondônia',
  id: 'RO'
}, {
  name: 'Roraima',
  id: 'RR'
}, {
  name: 'Santa Catarina',
  id: 'SC'
}, {
  name: 'São Paulo',
  id: 'SP'
}, {
  name: 'Sergipe',
  id: 'SE'
}, {
  name: 'Tocantins',
  id: 'TO'
}];

type StateProps = {
  register: Function,
  errors: any
};

export default function State({ register, errors }: StateProps) {
  const { state } = useAddress();
  const options = BRAZILIAN_STATES.map(({ name, id }) => (
    <option key={id} value={id}>{id} - {name}</option>
  ));
  return (
    <div>
      <Label htmlFor={INPUT_STATE_NAME}>Estado</Label>
      <InputContainer>
        <Select
          {...register(INPUT_STATE_NAME, {
            required: {
              value: true,
              message: 'Insira o Estado.'
            }
          })}
          defaultValue={state}
        >
          {options}
        </Select>
      </InputContainer>
      {errors && (
        <InputError>{errors?.message}</InputError>
      )}
    </div>
  );
}
