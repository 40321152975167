import React from "react";
import tailwind from "twin.macro";
import styled from "styled-components/macro";
import ApplePay from "./ApplePay";
import CreditCard from "./CreditCard";
import PaymentMethods from "./Methods";
import MethodAlert from "./MethodAlert";
import Pix from "./Pix";

const Heading = styled.h3`
  ${tailwind`
    text-lg
    font-medium
    text-gray-900
  `}
`;

const Container = styled.div`
  ${tailwind`
    mt-10
  `}
`;

type PaymentProps = {
  control: any;
  register: Function;
  errors: any;
};

export default function Payment({ control, register, errors }: PaymentProps) {
  return (
    <>
      <Container>
        <Heading>Pagamento</Heading>
        <PaymentMethods />
        <MethodAlert />
        <ApplePay />
        <Pix control={control} register={register} errors={errors?.pix} />
        <CreditCard control={control} register={register} errors={errors} />
      </Container>
    </>
  );
}
