import { Fragment, useEffect, useState } from "react";
import styled from "styled-components/macro";
import tailwind from "twin.macro";
import { useTheme } from "styled-components";
import Modal from "components/Modal/Modal";
import { useRedirectToECommerce } from "hooks/redirect-to-ecommerce";

const Icon = styled.div`
  ${tailwind`
    mx-auto
    flex
    items-center
    justify-center
    h-12
    w-12
    rounded-full
    bg-green-100
  `}

  svg {
    ${tailwind`
      animate-pulse
      h-6
      w-6
      text-green-600
    `}
  }
`;

const Heading = styled.h3`
  ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-900
  `}
`;

const Content = styled.div`
  ${tailwind`
    mt-2
  `}

  p {
    ${tailwind`
      text-sm
      text-gray-500
    `}
  }
`;

const Container = styled.div`
  ${tailwind`
    mt-3
    text-center
    sm:mt-5
  `}
`;

const Link = styled.a`
  ${tailwind`
    mt-5
    sm:mt-6
    inline-flex
    justify-center
    w-full
    rounded-md
    border
    border-transparent
    shadow-sm
    px-4
    py-2
    bg-green-600
    text-base
    font-medium
    text-white
    hover:bg-green-700
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-green-500
    sm:text-sm
  `}
  strong {
    ${tailwind`
      mx-1
    `}
  }
`;

type PaymentAuthorizedModalProps = {
  visible: boolean;
};

export default function PaymentAuthorizedModal({
  visible,
}: PaymentAuthorizedModalProps) {
  const { title } = useTheme();
  const [timeLeft, setTimeLeft] = useState(10);
  const { trackingUrl, isPix } = useRedirectToECommerce();

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (!timeLeft) {
      window.location.href = trackingUrl;
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [visible, timeLeft, trackingUrl]);

  if (!visible) {
    return <Fragment />;
  }

  let heading = "Pagamento Autorizado";

  if (title === "bb") {
    heading = "Pagamento aguardando aprovação";
  }

  if (isPix) {
    heading = "Pix Criado";
  }

  const content = isPix ? (
    <p>Agora você deve realizar o pagamento através do QR Code.</p>
  ) : (
    <>
      <p>O pagamento do seu pedido via cartão foi autorizado.</p>
      <p>
        <strong>A próxima etapa é aguardar a aprovação do seu pedido.</strong>
      </p>
    </>
  );
  const linkContent = isPix ? "Ver QR Code" : "Acompanhar meu pedido";

  return (
    <Modal>
      <div>
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </Icon>
        <Container>
          <Heading>{heading}</Heading>
          <Content>{content}</Content>
        </Container>
      </div>
      <Link href={trackingUrl} type="button">
        {linkContent} ({timeLeft})
      </Link>
    </Modal>
  );
}
