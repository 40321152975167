import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import CardValidator from "card-validator";
import { useKondutoContext } from "contexts/konduto";
import { useOrder } from "contexts/order";
import { useRiskifiedContext } from "contexts/riskified";
import { usePaymentMethod } from "contexts/payment-method";
import { useInstallments } from "contexts/installments";
import axios from "axios";
import { useCoupon } from "contexts/coupon";
import { calculateTotalAmount } from "utils/amount";
import { calculateTotalAmountWithDiscount } from "utils/coupon";
import { googleAnalyticsTrackConvertion } from "analytics";
import PaymentNotAuthorizedModal from "components/Payment/Modal/NotAuthorized";
import PaymentErrorModal from "components/Payment/Modal/Error";
import PaymentProcessingModal from "components/Payment/Modal/Processing";
import PaymentAuthorizedModal from "components/Payment/Modal/Authorized";
import {
  Container,
  Section,
  SecureCheckout,
  SubmitButton,
  SubmitContainer,
} from "./styles";
import Header from "components/Header";
import Customer from "components/Customer";
import Payment from "components/Payment";
import Address from "components/Address";
import Coupon from "components/Coupon";
import Agreement from "components/Agreement/Agreement";
import Summary from "components/Summary/Summary";
import { useRedirectToECommerce } from "hooks/redirect-to-ecommerce";

declare global {
  interface Window {
    opera?: any;
  }
}

export default function Checkout() {
  const { header } = useTheme();

  const {
    register,
    handleSubmit,
    trigger,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [processingPayment, setProcessingPayment] = useState(false);
  const [paymentAuthorized, setPaymentAuthorized] = useState(false);
  const [paymentNotAuthorized, setPaymentNotAuthorized] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentMsgError, setPaymentMsgError] = useState(null);

  const { protocol, debts } = useOrder();
  const { paymentMethod } = usePaymentMethod();
  const { dataPlans } = useInstallments();
  const { is_percent, discount } = useCoupon();
  const { total_amount } = calculateTotalAmount(
    dataPlans.plans,
    dataPlans.selected,
    paymentMethod
  );
  const baseAmount = debts
    .map(({ amount }) => amount)
    .reduce((a, b) => a + b, 0);
  const totalAmountWithDiscount = calculateTotalAmountWithDiscount({
    isPercent: is_percent,
    discount,
    totalAmount: total_amount,
    baseAmount,
  });

  const cart = debts.map(({ id }) => id);
  const { customerId } = useKondutoContext();
  const { sessionId } = useRiskifiedContext();
  const { code: couponCode } = useCoupon();
  const { trackingUrl } = useRedirectToECommerce();

  const onSubmit = (data) => {
    setProcessingPayment(true);
    setPaymentMsgError(null);

    let card: any = {};
    let pix = {};

    if (paymentMethod === "pix") {
      pix = {
        name: data.pix.name,
        document: data.pix.document.replace(/[^\d]/g, ""),
      };
    } else {
      let brand;

      if (paymentMethod === "ticketlog") {
        brand = "ticketlog";
      } else if (paymentMethod === "credit") {
        const cardType = CardValidator.number(
          data.card.number.replace(/[^\d]/g, "")
        );
        brand = cardType.card?.type;
      }

      card = {
        brand,
        number: data.card.number.replace(/[^\d]/g, ""),
        expirationDate: data.card.expiration_date.replace(/[^\d]/g, ""),
        cvv: data.card.cvv,
        holder: data.card.holder,
        document: data.card.document.replace(/[^\d]/g, ""),
        billingAddress: {
          zipCode: data.address.zipcode.replace(/[^\d]/g, ""),
          number: data.address.number,
          address: data.address.address,
          neighborhood: data.address.neighborhood,
          city: data.address.city,
          state: data.address.state,
        },
      };

      if (data.address.complement) {
        card = {
          ...card,
          billingAddress: {
            ...card.billingAddress,
            complement: data.address.complement,
          },
        };
      }
    }

    const mobile = () => {
      var check = false;
      (function (a, b) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(
        navigator.userAgent || navigator.vendor || window.opera,
        "http://detectmobilebrowser.com/mobile"
      );
      return check;
    };

    const customer = {
      email: data.customer.email,
      phone: data.customer.phone.replace(/[^\d]/g, ""),
    };

    const variables = {
      protocol,
      customer,
      promotionalTicketId: couponCode,
      debts: cart,
      kondutoVisitorId: customerId,
      card,
      pix,
      priority: false,
      installmentPlan: dataPlans.selected,
      paymentMethod,
      client_details: {
        accept_language: window.navigator.language,
        user_agent: window.navigator.userAgent,
        source: mobile() ? "mobileweb" : "desktop_web",
        ip: null,
        cart_token: sessionId,
      },
    };

    axios
      .post("/v2/detran/checkout/", variables)
      .then(() => {
        setPaymentAuthorized(true);
        googleAnalyticsTrackConvertion(
          protocol,
          discount ? totalAmountWithDiscount : total_amount
        );
      })
      .catch((error) => {
        if ((error?.response?.data?.error || "").includes("duplicado")) {
          setPaymentError(true);
          setPaymentMsgError(error?.response?.data?.detail);
          return;
        }

        if (error?.response?.data?.message) {
          window.location.href = trackingUrl;
          return;
        } else {
          setPaymentError(true);
        }
      })
      .finally(() => {
        setProcessingPayment(false);
      });
  };

  return (
    <>
      <PaymentErrorModal
        msgError={paymentMsgError}
        visible={paymentError}
        setVisibility={setPaymentError}
      />
      <PaymentProcessingModal control={control} visible={processingPayment} />
      <PaymentAuthorizedModal visible={paymentAuthorized} />
      <Section className={header?.modBar ? "mode-bar" : ""}>
        <Header />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Customer
              register={register}
              control={control}
              errors={errors?.customer}
            />
            <Payment control={control} register={register} errors={errors} />
            <Address
              control={control}
              register={register}
              errors={errors.address}
              trigger={trigger}
              setValue={setValue}
            />
            <Coupon control={control} register={register} />
            <Summary mobile={true} />
            <SubmitContainer>
              <Agreement />
              <SubmitButton type="submit">Pagar agora</SubmitButton>
              <SecureCheckout>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                <span>Ambiente de pagamento 100% seguro.</span>
              </SecureCheckout>
            </SubmitContainer>
          </Container>
        </form>
      </Section>
    </>
  );
}
