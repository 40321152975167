import tailwind from "twin.macro";
import styled from "styled-components/macro";
import { useInstallments } from "../../../contexts/installments";
import { useCoupon } from "contexts/coupon";
import { calculateTotalAmountWithDiscount } from "utils/coupon";
import { Theme } from "themes/types";

const Button = styled.button`
  ${tailwind`
    h-10
    relative
    w-full
    bg-white
    border
    border-gray-300
    rounded-md
    shadow-sm
    pl-3
    pr-10
    py-2
    text-left
    cursor-default
    focus:outline-none
    focus:ring-1
    sm:text-sm
  `}
  &:focus {
    ${({ theme }: { theme: Theme }) =>
      `
    border: 1px solid ${theme.colors.primary};
    box-shadow: 0 0 1px 1px ${theme.colors.primary};
  `}
  }
`;

const Amount = styled.span`
  ${tailwind`
    font-semibold
  `}
  ${({ theme }: { theme: Theme }) =>
    `
    color: ${theme.title === 'bb' ? '#E12C6D' : theme.colors.primary};
  `}
`;

const IconContainer = styled.span`
  ${tailwind`
    absolute
    inset-y-0
    right-0
    flex
    items-center
    pr-2
    pointer-events-none
  `}
`;

const SVG = styled.svg`
  ${tailwind`
    h-5
    w-5
    text-gray-400
  `}
`;

type InstallmentsSelectProps = {
  handleClick: Function;
  baseAmount: number;
};

type paymentPlansType = {
  installments: number;
  type: string;
};

export default function InstallmentsSelect({
  handleClick,
  baseAmount,
}: InstallmentsSelectProps) {
  const { dataPlans } = useInstallments();
  
  const installmentPlan = dataPlans.plans.find(
    ({ installments, type }: paymentPlansType) => {
      return type === "credit" && installments === dataPlans.selected;
    }      
  ) || { installments: 0, amount: 0, totalAmount: 0 };

  const { installments, amount, totalAmount } = installmentPlan;
  const { is_percent, discount, validated } = useCoupon();
  const totalAmountWithDiscount = calculateTotalAmountWithDiscount({
    isPercent: is_percent,
    discount,
    totalAmount,
    baseAmount,
  });
  const updatedAmount = validated
    ? totalAmountWithDiscount / installments
    : amount;

  return (
    <Button type="button" onClick={() => handleClick()}>
      <span>{installments}x de </span>
      <Amount>
        {updatedAmount.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
      </Amount>
      <IconContainer>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </SVG>
      </IconContainer>
    </Button>
  );
}
