import { Fragment } from "react";
import styled from "styled-components/macro";
import tailwind from "twin.macro";
import ZipCode from "./ZipCode";
import Alert from "./Alert";
// import AddressFetched from "./Address";
import CompleteAddress from "./Complete";
import ChangeAddress from "./Change";
import { AddressContextProvider } from "contexts/address";
import { usePaymentMethod } from "contexts/payment-method";

const Container = styled.div`
  ${tailwind`
    mt-10
  `}
`;

const Heading = styled.h3`
  ${tailwind`
    text-lg
    font-medium
    text-gray-900
  `}
`;

const Grid = styled.div`
  ${tailwind`
    mt-6
    grid
    grid-cols-1
    gap-y-6
    gap-x-4
    sm:grid-cols-2
  `}
`;

type AddressProps = {
  control: any;
  register: Function;
  trigger: Function;
  errors: any;
  setValue?: (key: string, value: any) => void;
};

export default function Address({
  control,
  register,
  errors,
  setValue,
  trigger,
}: AddressProps) {
  const { paymentMethod } = usePaymentMethod();

  if (!["credit", "ticketlog"].includes(paymentMethod)) {
    return <Fragment />;
  }

  return (
    <AddressContextProvider>
      <Container>
        <Heading>Endereço do Títular do Cartão</Heading>
        <Grid>
          <ZipCode
            control={control}
            trigger={trigger}
            setValue={setValue}
            errors={errors?.zipcode}
          />
        </Grid>
        <Alert />
        {/* <AddressFetched register={register} /> */}
        <ChangeAddress register={register} errors={errors} />
        <CompleteAddress register={register} errors={errors} />
      </Container>
    </AddressContextProvider>
  );
}
