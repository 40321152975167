import React from "react";
import { Grid, Heading } from "./styles";
import Email from "./Email";
import Phone from "./Phone";
import { useOrder } from "contexts/order";

type CustomerProps = {
  control: any;
  register: Function;
  errors: any;
};

export default function Customer({ control, register, errors }: CustomerProps) {
  const { customer } = useOrder();
  return (
    <>
      <Heading>Cliente</Heading>
      <Grid>
        <Email
          register={register}
          errors={errors?.email}
          email={customer?.email}
        />
        <Phone
          control={control}
          errors={errors?.phone}
          phone={customer?.phone}
        />
      </Grid>
    </>
  );
}
