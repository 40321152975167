import { Fragment } from "react";
import tailwind from "twin.macro";
import styled from "styled-components/macro";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CouponContextProvider } from "contexts/coupon";
import {
  InstallmentsContextProvider,
  useInstallments,
} from "contexts/installments";
import { PaymentMethodContextProvider } from "contexts/payment-method";
import "./styles.css";
import { OrderContextProvider, useOrder } from "contexts/order";
import { KondutoContextProvider } from "contexts/konduto";
import KondutoFingerPrint from "components/FingerPrints/Konduto";
import BraspagFingerPrint from "components/FingerPrints/Braspag";
import RiskifiedFingerPrint from "components/FingerPrints/Riskified";
import { ThemeProvider } from "contexts/themes";
import { Theme } from "themes/types";
import Loading from "components/Loading/Loading";
import Checkout from "components/Checkout/Checkout";
import Summary from "components/Summary/Summary";
import NotFound from "components/NotFound/NotFound";
import { Footer } from "components/footer";
import { useCheckAllowedTimeRange } from "hooks/check-allowed-time-range";
import { Alert } from "components/Alert";

const Container = styled.div`
  ${tailwind`
    bg-white
  `}
`;

const Main = styled.main`
  ${tailwind`
    relative
    lg:grid
    lg:grid-cols-10
    xl:ml-20
    xl:mr-12
    lg:px-8
  `}

  @media (min-width: 1024px) {
    min-height: calc(100vh - 69px);
  }
`;

const LeftBackground = styled.div`
  ${tailwind`
    hidden
    lg:block
    fixed
    top-0
    left-0
    w-2/3
    h-full
    bg-white
  `}
`;

const RightBackground = styled.div`
  ${tailwind`
    hidden
    lg:block
    fixed
    top-0
    right-0
    w-2/6
    h-full
    bg-gradient-to-b
    from-pink-800
    to-zapay
  `}
  ${({ theme }: { theme: Theme }) =>
    `
    --tw-gradient-from: ${theme.colors.primary};
    --tw-gradient-to: ${theme.colors.secondary};
  `}
`;

const ContainerOutsideSchedule = styled.div`
  ${tailwind`
    bg-[#9E1A3F]
    w-screen
    h-screen
    flex
    items-center
    justify-center
    flex-col
    gap-4
    px-5
    text-center
  `}
`;

const TitleOutsideSchedule = styled.div`
  ${tailwind`
    text-2xl 
    text-white 
    font-bold
  `}
`;

const DescriptionOutsideSchedule = styled.div`
  ${tailwind`
    text-sm 
    text-white
  `}
`;

function CheckoutApp() {
  const { fetched } = useOrder();
  const { states } = useInstallments();
  const { isWithinAllowedTimeRange } = useCheckAllowedTimeRange();

  if (process.env.REACT_APP_PAGE_UNDER_MAINTENANCE === 'true') {
    return (
      <ContainerOutsideSchedule>
        <Alert />
        <TitleOutsideSchedule>
          Não foi possível carregar a página.
        </TitleOutsideSchedule>
        <DescriptionOutsideSchedule>
        Pagamento por este canal indisponivel para a data de hoje
        </DescriptionOutsideSchedule>
      </ContainerOutsideSchedule>
    );
  }

  if (!fetched || states.loading) {
    return <Fragment />;
  }

  if (!isWithinAllowedTimeRange) {
    return (
      <ContainerOutsideSchedule>
        <Alert />
        <TitleOutsideSchedule>
          Não foi possível carregar seu pedido
        </TitleOutsideSchedule>
        <DescriptionOutsideSchedule>
          O horário limite para pagamentos nessa modalidade é até as 22:00hs
        </DescriptionOutsideSchedule>
      </ContainerOutsideSchedule>
    );
  }

  return (
    <Container>
      <LeftBackground aria-hidden="true" />
      <RightBackground aria-hidden="true" />
      <Main>
        <CouponContextProvider>
          <PaymentMethodContextProvider>
            <Checkout />
            <Summary mobile={false} />
          </PaymentMethodContextProvider>
        </CouponContextProvider>
      </Main>
      <Footer />
    </Container>
  );
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:protocol">
          <OrderContextProvider>
            <InstallmentsContextProvider>
              <KondutoContextProvider>
                <RiskifiedFingerPrint />
                <KondutoFingerPrint page="checkout" />
                <BraspagFingerPrint />
                <ThemeProvider>
                  <Loading />
                  <NotFound />
                  <CheckoutApp />
                </ThemeProvider>
              </KondutoContextProvider>
            </InstallmentsContextProvider>
          </OrderContextProvider>
        </Route>
      </Switch>
    </Router>
  );
}
