import { Controller } from "react-hook-form";
import tailwind from "twin.macro";
import styled from "styled-components/macro";
import axios from "axios";
import { useAddress } from "contexts/address";
import { INPUT_ADDRESS_NAME, INPUT_CITY_NAME, INPUT_NEIGHBORHOOD_NAME, INPUT_STATE_NAME, INPUT_ZIPCODE_NAME } from "./constants";
import NumberFormat from "react-number-format";
import { useState } from "react";

const Container = styled.div`
  ${tailwind`
    col-span-2
    sm:col-span-1
  `}
`;

const Label = styled.label`
  ${({ error }: { error: any }) =>
    error
      ? tailwind`
    text-red-600
  `
      : tailwind`
    text-gray-700
  `}

  ${tailwind`
    block
    text-sm
    font-medium
  `}
`;

const InputContainer = styled.div`
  ${tailwind`
    mt-1
    flex
    rounded-md
    shadow-sm
  `}
`;

const InputInnerContainer = styled.div`
  ${({ error }: { error: any }) =>
    error
      ? tailwind`
    border-red-300
  `
      : tailwind`
    border-gray-300
  `}
  ${tailwind`
    relative
    flex
    items-stretch
    flex-grow
    focus-within:z-10
    border
    focus:border-indigo-500
    rounded-md
  `}
`;

const InputIconContainer = styled.div`
  ${tailwind`
    absolute
    inset-y-0
    left-0
    pl-3
    flex
    items-center
    pointer-events-none
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-gray-400
    `}
  }
`;

const Input = styled.input`
  ${tailwind`
    focus:ring-indigo-500
    h-10
    block
    w-full
    rounded-none
    rounded-md
    pl-10
    sm:text-sm
  `}
`;

const InputError = styled.p`
  ${tailwind`
    mt-2
    text-sm
    text-red-600
  `}
`;

const InputIconErrorContainer = styled.div`
  ${tailwind`
    absolute
    inset-y-0
    right-0
    pr-3
    flex
    items-center
    pointer-events-none
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-red-500
    `}
  }
`;

type ZipCodeProps = {
  control: any;
  trigger: Function;
  errors: any;

  setValue?: (key: string, value: any) => void;
};

function validateZipCode(value: string) {
  const digits = value?.replace(/[^\d]/g, "");
  if (digits.length < 8) {
    return "CEP incompleto.";
  }
}

export default function ZipCode({ control, errors, trigger, setValue }: ZipCodeProps) {
  const { setAddress } = useAddress();
  const [fetched, setFetched] = useState(false);

  async function fetchAddressByZipCode(value: string) {
    const zipcode = value?.replace(/[^\d]/g, "");

    if (fetched) {
      return;
    }

    try {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`
      );
      const { bairro, localidade, logradouro, uf } = data;
      if (!localidade || !bairro || !logradouro) {
        setAddress({
          change: true,
          fetched: true,
          success: false,
          state: uf,
          city: localidade,
          address: logradouro,
          neighborhood: bairro,
        });
      } else {
        setAddress({
          fetched: true,
          success: true,
          state: uf,
          city: localidade,
          address: logradouro,
          neighborhood: bairro,
        });
      }

      setValue?.(INPUT_STATE_NAME, uf);
      setValue?.(INPUT_CITY_NAME, localidade);
      setValue?.(INPUT_ADDRESS_NAME, logradouro);
      setValue?.(INPUT_NEIGHBORHOOD_NAME, bairro);
    } catch {
      setAddress({
        change: true,
        fetched: true,
        success: false,
        city: "",
        address: "",
        state: "AC",
        neighborhood: "",
      });
    } finally {
      setFetched(true);
      setAddress((s) => ({
        ...s,
        zipcode,
      }));
      trigger([INPUT_ZIPCODE_NAME]);
    }
    return true;
  }

  return (
    <Container>
      <Label error={errors} htmlFor={INPUT_ZIPCODE_NAME}>
        CEP
      </Label>
      <InputContainer>
        <InputInnerContainer error={errors}>
          <InputIconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </InputIconContainer>
          <Controller
            name={INPUT_ZIPCODE_NAME}
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: "Insira o CEP.",
              },
              validate: {
                validateZipCode,
                fetchAddressByZipCode,
              },
            }}
            render={({ field: { ref, value, onChange, onBlur } }) => (
              <NumberFormat
                format="#####-###"
                value={value}
                // onChange={onChange}
                onChange={(e) => {
                  onChange(e);
                  setFetched(false);
                }}
                onBlur={onBlur}
                autoComplete="postal-code"
                placeholder="00000-000"
                customInput={Input}
                // error={errors}
                getInputRef={ref}
              />
            )}
          />
          {errors && (
            <InputIconErrorContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </InputIconErrorContainer>
          )}
        </InputInnerContainer>
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </Container>
  );
}
