import styled from "styled-components/macro";
import tailwind from "twin.macro";
import { Theme } from "themes/types";

export const Section = styled.section`
  ${({ mobile }: { mobile: boolean }) =>
    mobile
      ? tailwind`
    block
    lg:hidden
  `
      : tailwind`
    hidden
    lg:block
  `}

  ${tailwind`
    -mx-4
    lg:mx-0
    my-10
    lg:my-0
    text-gray-500
    lg:text-indigo-200
    pt-6
    pb-12
    bg-gray-50
    px-6
    xl:pl-8
    lg:pl-4
    lg:pr-2
    xl:pr-0
    lg:pt-0
    lg:pb-24
    lg:bg-transparent
    lg:col-span-4
    lg:row-start-1
    lg:col-start-8
  `}
`;

export const Heading = styled.div`
  ${tailwind`
    font-semibold
    lg:text-white
    text-gray-900
    text-lg
  `}
`;

export const Container = styled.div`
  ${tailwind`
    sticky
    top-0
    lg:max-w-none
    lg:pt-24
  `}
`;

export const TotalAmountContainer = styled.div`
  ${tailwind`
    flex
    items-center
    justify-between
    border-t
    border-gray-200
    lg:border-white
    lg:border-opacity-10
    text-gray-900
    lg:text-white
    pt-6
    mt-6
    text-base
  `}
`;

export const HeadingContainer = styled.div`
  ${tailwind`
    flex
    items-center
    justify-between
    mb-4
  `}
`;

export const HeadingButton = styled.div`
  ${tailwind`
    font-medium
    hover:text-indigo-500
    text-xs
    lg:hidden
    flex
    items-center
    bg-transparent
    appearance-none
  `}
  ${({ theme }: { theme: Theme }) =>
    `
    color: ${theme.colors.primary};
  `}
    cursor: pointer;
  svg {
    ${tailwind`
      ml-1
      w-4
      h-4
    `}
  }
`;

export const SubtotalAmount = styled.dl`
  ${tailwind`
    text-sm
    font-medium
    space-y-6
    border-t
    border-gray-200
    lg:border-white
    lg:border-opacity-10
    mt-6
    pt-6
  `}

  div {
    ${tailwind`
      flex
      items-center
      justify-between
    `}
  }

  div.service {
    dt, dd, span {
      color: #DC3545;
      font-weight: 800;
    }
  }
`;

export const CouponBadge = styled.span`
  ${tailwind`
    ml-2
    rounded-full
    bg-gray-200
    text-xs
    text-gray-600
    py-0.5
    px-2
    tracking-wide
  `}
`;
