type ParamsType = {
  totalAmount: number;
  baseAmount: number;
  isPercent: boolean;
  discount: number;
};

export const calculateTotalDiscountAmount = ({ totalAmount, baseAmount, isPercent, discount }: ParamsType) => {
  const fees = totalAmount - baseAmount;
  return (isPercent ? fees * (discount/100) : discount); 
}

export const calculateTotalAmountWithDiscount = (params: ParamsType) => {
  const { totalAmount } = params;
  return totalAmount - calculateTotalDiscountAmount(params)
}
