// import React from 'react';
// import { useCoupon } from 'contexts/coupon';
// import { calculateTotalDiscountAmount } from 'utils/coupon';
import { useOrder } from 'contexts/order';
import { usePaymentMethod } from 'contexts/payment-method';
import { useInstallments } from 'contexts/installments';
import { calculateTotalAmount } from 'utils/amount';

export default function ServiceCharge() {
  const { debts } = useOrder();
  const { dataPlans } = useInstallments();
  const { paymentMethod } = usePaymentMethod();
  const { total_amount } = calculateTotalAmount(
    dataPlans.plans,
    dataPlans.selected,
    paymentMethod
  ); 
  
  const base_amount = debts.map(({ amount }) => amount).reduce((a, b) => a + b, 0);
  
  const value = total_amount - base_amount;
  return <span>{value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>;
}