import React from 'react';
import ErrorModal from './Error';

type ExpiredModalProps = {
  visible: boolean;
  toggleVisibility: Function;
};

export default function ExpiredModal(props: ExpiredModalProps) {
  return (
    <ErrorModal
      {...props}
      heading="Cupom Expirado"
      content={(
        <p>Este cupom não está mais disponível para uso.</p>
      )}
    />
  );
}
