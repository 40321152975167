import {
  Label,
  InputContainer,
  Input,
  InputIconErrorContainer,
  InputError,
} from "./styles";

const INPUT_ADDRESS_NUMBER_NAME = "address.number";

type NumberProps = {
  register: Function;
  errors: any;
};

export default function Number({ register, errors }: NumberProps) {
  return (
    <div>
      <Label error={errors} htmlFor={INPUT_ADDRESS_NUMBER_NAME}>
        Número
      </Label>
      <InputContainer>
        <Input
          {...register(INPUT_ADDRESS_NUMBER_NAME, {
            required: {
              value: true,
              message: "Insira o número.",
            },
          })}
          type="text"
          autoComplete="off"
          defaultValue=""
          inputmode="numeric"
          error={errors}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </div>
  );
}
