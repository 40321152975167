// @ts-nocheck

export function konduto(publicKey: string) {
  if (window.__kdt && window.__kdt.find((kdt) => kdt.public_key)) {
    return;
  }
  const __kdt = window.__kdt || [];
  __kdt.push({ public_key: publicKey }); // A chave pública identifica a sua loja
  __kdt.push({ post_on_load: false }); // Envio automático desativado
  (function () {
    var kdt = document.createElement("script");
    kdt.id = "kdtjs";
    kdt.type = "text/javascript";
    kdt.async = true;
    kdt.src = "https://i.k-analytix.com/k.js";
    var s = document.getElementsByTagName("body")[0];
    s.parentNode.insertBefore(kdt, s);
  })();
}
