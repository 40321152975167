import React from 'react';
import { useCoupon } from 'contexts/coupon';
import { calculateTotalDiscountAmount } from 'utils/coupon';
import { useOrder } from 'contexts/order';
import { usePaymentMethod } from 'contexts/payment-method';
import { useInstallments } from 'contexts/installments';
import { calculateTotalAmount } from 'utils/amount';

export default function DiscountAmount() {
  const { debts } = useOrder();
  const { dataPlans } = useInstallments();
  const { is_percent, discount } = useCoupon();
  const { paymentMethod } = usePaymentMethod();
  const { total_amount } = calculateTotalAmount(dataPlans.plans, dataPlans.selected, paymentMethod); 
  const baseAmount = debts.map(({ amount }) => amount).reduce((a, b) => a + b, 0);
  const params = {
    isPercent: is_percent,
    discount,
    totalAmount: total_amount,
    baseAmount
  };
  const discountAmount = calculateTotalDiscountAmount(params);
  return <span>{discountAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>;
}
